import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import PropTypes from "prop-types";
import "./mobile-phone-input.scss";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/min";

const MobilePhoneInput = ({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  disabled,
}) => {
  const inputRef = useRef();
  const [valueShow, setValueShow] = useState(null);
  const [country, setCountry] = useState("au");
  useEffect(() => {
    if (!value) {
      setValueShow("");
      return;
    }
    const isValid = isValidPhoneNumber(value);
    if (isValid) {
      const parsedValue = parsePhoneNumber(value);
      if (parsedValue.country.toLowerCase() !== country) {
        setCountry(parsedValue.country.toLowerCase());
      }
      setValueShow(parsedValue.nationalNumber);
      return;
    }
    setValueShow(value);
  }, [value, country]);

  const handleChangePhoneNumber = (phoneValue, countryData) => {
    setCountry(countryData.countryCode);
    const isValid = isValidPhoneNumber(
      phoneValue,
      countryData.countryCode.toUpperCase()
    );
    onChange({
      phoneNumber: isValid
        ? parsePhoneNumber(
            phoneValue,
            countryData.countryCode.toUpperCase()
          ).format("E.164")
        : phoneValue,
      countryCode: countryData.countryCode,
      dialCode: countryData.dialCode,
    });
  };

  const handleBlur = () => {
    onBlur();
  };

  return (
    <div className="quickloan-phone-input-wrapper">
      {valueShow != null && (
        <PhoneInput
          containerClass="quickloan-phone-input-container"
          inputClass="quickloan-phone-input-input"
          ref={inputRef}
          value={valueShow}
          placeholder="Input your phone number"
          masks={{
            au: ".... ... ... ....",
            nz: ".... ... ... ....",
          }}
          enableSearch={false}
          onlyCountries={["au", "nz"]}
          disableCountryCode
          country={country}
          onBlur={handleBlur}
          disabled={disabled}
          onChange={handleChangePhoneNumber}
          isValid={() => {
            if (error) return helperText;
            return true;
          }}
        />
      )}
    </div>
  );
};

MobilePhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  disabled: PropTypes.bool,
};

export default MobilePhoneInput;
